import React from 'react';
import { isObservable, of, combineLatestWith, tap } from 'rxjs';
import type { Observable } from 'rxjs';

interface ObservableResult {
  loading: boolean;
  called: boolean;
  ready: boolean;
  error: unknown;
}

type ObservableInput<T> = Observable<T>;
type ObservableSource<T> = ObservableInput<T> | { source$: ObservableInput<T>; loading$: ObservableInput<boolean> };
type InternalDataSource<T> = { data$: ObservableInput<T>; loading$: ObservableInput<boolean> | undefined };

const normalize = <T>(observable$: ObservableSource<T>): InternalDataSource<T> => ({
  data$: isObservable(observable$) ? observable$ : observable$.source$,
  loading$: isObservable(observable$) ? undefined : observable$.loading$,
});

function useReadonlyObservable<T>(
  observable$: ObservableSource<T>,
  initialState: T
): [data: T, result: ObservableResult];

function useReadonlyObservable<T>(
  observable$: ObservableSource<T>,
  initialState: Partial<T>
): [data: Partial<T>, result: ObservableResult];

function useReadonlyObservable<T>(
  observable$: ObservableSource<T>,
  initialState?: T
): [data: T | undefined, result: ObservableResult];

function useReadonlyObservable<T>(
  observable$: ObservableSource<T>,
  initialState?: T
): [data: T | undefined, result: ObservableResult] {
  const source = React.useRef(normalize(observable$));
  const [data, setData] = React.useState<T | undefined>(initialState);
  const [loading, toggleLoading] = React.useState<ObservableResult['loading']>(false);
  const [called, toggleCalled] = React.useState<ObservableResult['called']>(false);
  const [error, setError] = React.useState<ObservableResult['error']>(undefined);

  React.useEffect(() => {
    if (!source.current) return;

    toggleLoading(true);

    const subscription = source.current.data$
      .pipe(
        tap(() => {
          toggleCalled(true);
        }),
        combineLatestWith(source.current.loading$ ?? of(false))
      )
      .subscribe({
        next([value, requesting]) {
          setData(value);

          setError(undefined);

          toggleLoading(requesting);
        },
        complete() {
          toggleLoading(false);
        },
        error(err) {
          setError(err);

          toggleCalled(true);

          toggleLoading(false);

          console.error('[useReadonlyObservable]:', err);
        },
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return [
    data,
    {
      loading,
      called,
      ready: !loading && called,
      error,
    },
  ];
}

export default useReadonlyObservable;
