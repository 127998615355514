import type { MutableRefObject } from 'react';
import React from 'react';
import is from 'utils/is';

/*
 eslint-disable @typescript-eslint/no-unsafe-argument,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-unsafe-return
 */
const useResizeObserver = <
  T extends HTMLElement | undefined,
  C extends (element: MutableRefObject<Exclude<T, undefined>>) => any,
>(
  element: MutableRefObject<T>,
  callback: C
): ReturnType<C> | undefined => {
  const [returnedValue, setCallbackReturnValue] = React.useState<ReturnType<C>>();

  React.useLayoutEffect(() => {
    if (is.nullish(element?.current)) return;

    const handleCallback = (): void => {
      setCallbackReturnValue(callback(element as MutableRefObject<Exclude<T, undefined>>));
    };

    handleCallback();

    const observer = new ResizeObserver(handleCallback);

    observer.observe(element.current);

    return () => {
      if (!element?.current) return;

      observer.unobserve(element?.current);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current, callback]);

  return returnedValue;
};
/*
 eslint-enable @typescript-eslint/no-unsafe-argument,
 @typescript-eslint/no-explicit-any,
 @typescript-eslint/no-unsafe-return
*/

export default useResizeObserver;
