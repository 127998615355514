import React from 'react';
import { useIntl } from 'react-intl';
import toast from 'services/toast';
import type { Toast } from 'contracts/internals/toast';
// eslint-disable-next-line no-restricted-imports
import Icon, { IconList } from 'components/icon';
// eslint-disable-next-line no-restricted-imports
import messages from 'components/toast/toast.i18n';

type Template = Omit<Toast, 'id' | 'message'>;

const successTemplate: Template = {
  icon: <Icon name={IconList.checkCircularSolid} className="color-alert-success_500" />,
  closable: true,
  timeout: 5000,
};

const warningTemplate: Template = {
  icon: <Icon name={IconList.warningCircularSolid} className="color-yellow-500" />,
  closable: true,
  timeout: 5000,
};

const errorTemplate: Template = {
  icon: <Icon name={IconList.closeCircularSolid} className="color-alert-danger_500" />,
  closable: true,
  timeout: 5000,
};

interface UseToast {
  success: (overrides: Partial<Toast> & { message: Toast['message'] }) => symbol;
  warning: (overrides?: Partial<Toast>) => symbol;
  error: (overrides?: Partial<Toast>) => symbol;
}

const useToast = (): UseToast => {
  const { formatMessage } = useIntl();

  const success: UseToast['success'] = React.useCallback(
    (overrides) => {
      const id = overrides?.id ?? Symbol('success');

      toast.dispatch({
        id,
        ...successTemplate,
        ...overrides,
      });

      return id;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage]
  );

  const warning: UseToast['warning'] = React.useCallback(
    (overrides) => {
      const id = overrides?.id ?? Symbol('warning');

      toast.dispatch({
        id,
        message: formatMessage(messages.components.toast.generic.error),
        ...warningTemplate,
        ...overrides,
      });

      return id;
    },
    [formatMessage]
  );

  const error: UseToast['error'] = React.useCallback(
    (overrides) => {
      const id = overrides?.id ?? Symbol('error');

      toast.dispatch({
        id,
        message: formatMessage(messages.components.toast.generic.error),
        ...errorTemplate,
        ...overrides,
      });

      return id;
    },
    [formatMessage]
  );

  return { success, warning, error };
};

export default useToast;
