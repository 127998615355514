import type { ComponentType, LazyExoticComponent } from 'react';
import application from 'services/application';
import is from 'utils/is';
import typeOf from 'utils/type-of';
import type { Product } from 'contracts';

import useSuspenseLazyLoad from './use-suspense-lazy-load';

/* eslint-disable @typescript-eslint/no-explicit-any */
type LazyComponent<T extends ComponentType<any>> = LazyExoticComponent<T>;

const validate = <T>(callback: T): void => {
  if (is.func(callback)) {
    return;
  }

  throw new TypeError(`[useProductModule]: Expected argument to be a "function" but instead got "${typeOf(callback)}"`);
};

const useProductModule = <T extends ComponentType<any>>(
  factory: (product: Product) => Promise<{ default: T }>,
  dependencies: Array<any> = []
): LazyComponent<T> => {
  validate(factory);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return useSuspenseLazyLoad(() => factory(application.current), [application.current, ...dependencies]);
};

/* eslint-enable @typescript-eslint/no-explicit-any */

export default useProductModule;
