import React from 'react';
import is from 'utils/is';
import type { KeyboardKey } from 'contracts';

type KeydownList = Array<KeyboardKey>;
type KeydownCallbackFn<T extends Element> = (key: KeyboardKey, event: React.KeyboardEvent<T>) => void;
type KeydownCallbackList<T extends Element> = Partial<Record<KeyboardKey, KeydownCallbackFn<T>>>;
type KeydownListener<T extends Element> = (event: React.KeyboardEvent<T>) => void;

function useKeydownEvent<T extends Element>(
  callback: KeydownCallbackFn<T> | KeydownCallbackList<T>
): KeydownListener<T>;

function useKeydownEvent<T extends Element>(
  events: KeydownList,
  callback: KeydownCallbackFn<T> | KeydownCallbackList<T>
): KeydownListener<T>;

function useKeydownEvent<T extends Element>(
  events: KeydownList | KeydownCallbackFn<T> | KeydownCallbackList<T>,
  callback?: KeydownCallbackFn<T> | KeydownCallbackList<T>
): KeydownListener<T> {
  React.useEffect(() => {
    if (!is.array(events) || !is.object(callback)) return;

    const undeclared = events.filter((event) => !is.keyOf(callback, event));

    if (undeclared.length) {
      console.warn(
        '[useKeydownEvent]: The following keyboard events are being listened and does not have callback attached:\n',
        undeclared.map((e) => `- ${e}`)
      );
    }
  }, [events, callback]);

  return React.useCallback(
    (event) => {
      const key = event.key as KeyboardKey;

      if (is.array(events) && !events.includes(key)) {
        return;
      }

      if (is.nullish(callback) && !is.array(events)) {
        return is.object(events) ? events?.[key]?.(key, event) : events(key, event);
      }

      return is.object(callback) ? callback?.[key]?.(key, event) : callback?.(key, event);
    },
    [events, callback]
  );
}

export default useKeydownEvent;
