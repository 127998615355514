import type { ComponentType, LazyExoticComponent } from 'react';
import React from 'react';
import is from 'utils/is';
import typeOf from 'utils/type-of';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyComponent<T extends ComponentType<any>> = LazyExoticComponent<T>;

const validate = <T>(callback: T): void => {
  if (is.func(callback)) {
    return;
  }

  throw new TypeError(
    `[useSuspenseLazyLoad]: Expected argument to be a "function" but instead got "${typeOf(callback)}"`
  );
};

/**
 * Lazy Load ES Modules with built-in bail fail-safe.
 * @desc it bails if any of the dependencies are nullish
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSuspenseLazyLoad = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: Array<any>
): LazyComponent<T> => {
  validate(factory);

  if (dependencies.some(is.nullish)) {
    // @ts-expect-error: type is expected not to be assignable to LazyComponent<T>.
    // Following structure is a requirement to make React.Suspense compatible.
    return () => {
      // eslint-disable-next-line promise/avoid-new
      throw new Promise<void>(() => {});
    };
  }

  return React.lazy(factory);
};

export default useSuspenseLazyLoad;
