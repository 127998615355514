import React from 'react';
import settings from 'configurations/application';

interface UseBodyScrollLockActions {
  lock: () => void;
  unlock: () => void;
  toggle: () => void;
  locked: boolean;
}

const useBodyScrollLock = (autoExecute?: boolean): UseBodyScrollLockActions => {
  const [locked, setLocked] = React.useState(false);

  const lock = React.useCallback((): void => {
    document.getElementById(settings.react.rootDivId)?.classList.add('overflow-hidden');
    setLocked(true);
  }, []);

  const unlock = React.useCallback((): void => {
    document.getElementById(settings.react.rootDivId)?.classList.remove('overflow-hidden');
    setLocked(false);
  }, []);

  const toggle = React.useCallback((): void => {
    if (locked) {
      unlock();
    } else {
      lock();
    }
  }, [locked]);

  React.useEffect(() => {
    if (!autoExecute) return;

    lock();

    return unlock;
  }, [autoExecute]);

  return {
    locked,
    lock,
    unlock,
    toggle,
  };
};

useBodyScrollLock.defaultProps = {
  autoExecute: false,
};

export default useBodyScrollLock;
