/**
 * Keyboard Event key Attribute Values
 * @see {https://www.w3.org/TR/uievents-key/#keys-special}
 */
enum KeyboardKey {
  // Modifier Keys
  Alt = 'Alt',
  CapsLock = 'CapsLock',
  Control = 'Control',
  Fn = 'Fn',
  FnLock = 'FnLock',
  NumLock = 'NumLock',
  ScrollLock = 'ScrollLock',
  Shift = 'Shift',
  Symbol = 'Symbol',
  SymbolLock = 'SymbolLock',
  // Whitespace Keys
  Enter = 'Enter',
  Tab = 'Tab',
  Spacebar = ' ',
  // Navigation Keys
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  End = 'End',
  Home = 'Home',
  PageDown = 'PageDown',
  PageUp = 'PageUp',
  // Editing Keys
  Backspace = 'Backspace',
  Clear = 'Clear',
  Copy = 'Copy',
  CrSel = 'CrSel',
  Cut = 'Cut',
  Delete = 'Delete',
  ExSel = 'ExSel',
  Insert = 'Insert',
  Paste = 'Paste',
  Redo = 'Redo',
  Undo = 'Undo',
  // UI Keys
  Accept = 'Accept',
  Again = 'Again',
  Attn = 'Attn',
  Cancel = 'Cancel',
  ContextMenu = 'ContextMenu',
  Escape = 'Escape',
  Execute = 'Execute',
  Find = 'Find',
  Help = 'Help',
  Pause = 'Pause',
  Props = 'Props',
  Select = 'Select',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
  // Device Keys
  BrightnessDown = 'BrightnessDown',
  BrightnessUp = 'BrightnessUp',
  Eject = 'Eject',
  LogOff = 'LogOff',
  Power = 'Power',
  PowerOff = 'PowerOff',
  PowerDown = 'PowerDown',
  PrintScreen = 'PrintScreen',
  Hibernate = 'Hibernate',
  Standby = 'Standby',
  WakeUp = 'WakeUp',
  // General-Purpose Function Keys
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
  // Multimedia Keys
  ChannelDown = 'ChannelDown',
  ChannelUp = 'ChannelUp',
  Close = 'Close',
  MailForward = 'MailForward',
  MailReply = 'MailReply',
  MailSend = 'MailSend',
  MediaClose = 'MediaClose',
  MediaFastForward = 'MediaFastForward',
  MediaPause = 'MediaPause',
  MediaPlay = 'MediaPlay',
  MediaPlayPause = 'MediaPlayPause',
  MediaRecord = 'MediaRecord',
  MediaRewind = 'MediaRewind',
  MediaStop = 'MediaStop',
  MediaTrackNext = 'MediaTrackNext',
  MediaTrackPrevious = 'MediaTrackPrevious',
  New = 'New',
  Open = 'Open',
  Print = 'Print',
  Save = 'Save',
  SpellCheck = 'SpellCheck',
}

export { KeyboardKey };
