import React from 'react';
import { debounce } from 'throttle-debounce';
import breakpoints from 'utils/breakpoints';

const useMobileView = (): boolean => {
  const [mobile, setMobile] = React.useState<boolean>(breakpoints.max('md'));

  const handleWindowResize = React.useMemo(() => {
    return debounce(
      350,
      (): void => {
        setMobile(breakpoints.max('md'));
      },
      { atBegin: false }
    );
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mobile;
};

export default useMobileView;
