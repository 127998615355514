import React from 'react';
import { onHistoryChange$ } from 'services/routing/events';
import location from 'services/routing/location';
import navigation, { link } from 'services/navigation';
import type { Navigation, NavigationLink } from 'contracts';

type NavigationParams = Record<string, string>;
type UseNavigation = [navigation: Navigation, parameters: NavigationParams, link: NavigationLink];

const useNavigation = (globalParams = false): UseNavigation => {
  const [params, updateParameters] = React.useState<NavigationParams>({
    ...location.params(),
    ...location.searchParams(globalParams),
    ...location.hashParams(globalParams),
  });

  React.useLayoutEffect(() => {
    const subscription = onHistoryChange$.subscribe(() => {
      updateParameters({
        ...location.params(),
        ...location.searchParams(globalParams),
        ...location.hashParams(globalParams),
      } satisfies NavigationParams);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [globalParams]);

  return [navigation, params, link];
};

export type { UseNavigation };
export default useNavigation;
