import React from 'react';
import { throttle } from 'throttle-debounce';
import css from 'utils/css';
import is from 'utils/is';

type StyleDimensions = Pick<
  CSSStyleDeclaration,
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'borderRadius'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomLeftRadius'
  | 'borderBottomRightRadius'
>;

type DOMDimensions = Pick<DOMRect, 'top' | 'bottom' | 'left' | 'right' | 'width' | 'height'>;

interface Dimensions extends DOMDimensions, StyleDimensions {
  innerWidth?: number;
  innerHeight?: number;
}

type UseNodeDimensions = <T extends HTMLElement | SVGElement = HTMLElement>(
  updateOnWindowResize?: boolean
) => [React.MutableRefObject<T | undefined>, Dimensions | undefined];

const useNodeDimensions: UseNodeDimensions = <T extends HTMLElement | SVGElement = HTMLElement>(
  updateOnWindowResize = true,
  throttleDelay = 50
) => {
  const [dimensions, updateDimensions] = React.useState<Dimensions | undefined>(undefined);
  const elementRef = React.useRef<T>();

  const handleDimensions = React.useMemo(() => {
    return throttle(throttleDelay, (): void => {
      updateDimensions((value) => {
        if (!elementRef.current || (!updateOnWindowResize && !is.nullish(value))) {
          return value;
        }

        const {
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          borderRadius,
          borderTopLeftRadius,
          borderTopRightRadius,
          borderBottomLeftRadius,
          borderBottomRightRadius,
        }: Partial<StyleDimensions> = window.getComputedStyle(elementRef.current, null);

        const { top, bottom, left, right, width, height }: DOMDimensions = elementRef.current.getBoundingClientRect();

        const innerHeight = height - (css(paddingTop).size + css(paddingBottom).size);
        const innerWidth = width - (css(paddingLeft).size + css(paddingRight).size);

        return {
          top,
          bottom,
          left,
          right,
          width,
          height,
          innerWidth,
          innerHeight,
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
          borderRadius,
          borderTopLeftRadius,
          borderTopRightRadius,
          borderBottomLeftRadius,
          borderBottomRightRadius,
        };
      });
    });
  }, [throttleDelay, updateOnWindowResize]);

  React.useLayoutEffect(() => {
    handleDimensions();

    if (!updateOnWindowResize) {
      return;
    }

    window.addEventListener('resize', handleDimensions);

    return (): void => {
      window.removeEventListener('resize', handleDimensions);
    };
  }, [updateOnWindowResize, handleDimensions]);

  return [elementRef, dimensions];
};

export type { UseNodeDimensions, Dimensions };
export default useNodeDimensions;
