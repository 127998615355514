// eslint-disable-next-line filenames/match-exported
import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { sendTracking, type TrackingEvent } from 'services/google-analytics';
import is from 'utils/is';
import type { PickType } from 'contracts';

const getDisplayName = function DisplayName<T>(ReactComponent: React.FunctionComponent<T>): string {
  return ReactComponent.displayName || ReactComponent.name || 'Component';
};

interface Options<T> {
  /* Tracking action type. E.g. click, view, open, close */
  action: string;
  /** Property to intercept from the enhanced component */
  handler: keyof PickType<Required<T>, Function>;
}

interface WithGaTracking {
  /** Google Analytics tracking event */
  tracking?: TrackingEvent;
}

const withGATracking = function GATracking<T>(
  Component: React.FunctionComponent<T>,
  options: Options<T>
): React.FunctionComponent<T & WithGaTracking> {
  const { action, handler } = { ...options };

  const GATrackingEvent: React.FunctionComponent<T & WithGaTracking> = (props: T & WithGaTracking) => {
    const { [handler]: onAction, tracking, ...rest } = props;

    const handleAction = (...args: unknown[]): void => {
      if (is.object(tracking) && Object.keys(tracking).length) {
        // @ts-expect-error: action property overwrite is a desired effect
        sendTracking({ action, ...tracking });
      }

      if (is.func(onAction)) {
        onAction(...args);
      }
    };

    const newProps = {
      [handler as string]: handleAction,
    };

    // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...rest} {...newProps} />;
  };

  GATrackingEvent.displayName = `withGATracking(${getDisplayName(Component)})`;

  hoistNonReactStatic(GATrackingEvent, Component);

  return GATrackingEvent;
};

export type { WithGaTracking };
export default withGATracking;
